import React from 'react';
import cn from 'classnames';
import { Variant } from '~/common/models';
import { useRecoilState } from 'recoil';
import { ActiveVariant } from '../../store';
import styles from './index.module.scss';

interface VariantTabProps {
  variants: Variant[];
}

export const VariantTab = React.memo<VariantTabProps>(({ variants }) => {
  const [activeVariant, setActiveVariant] = useRecoilState(ActiveVariant);
  return (
    <div className={styles.variantTabContainer}>
      <div className={styles.variantTab}>
        {variants.map((variant) => (
          <VariantTabItem
            key={variant.id}
            variantName={variant.shortName}
            isActive={variant.id === activeVariant?.id}
            onClick={() => setActiveVariant(variant)}
          />
        ))}
      </div>
    </div>
  );
});

interface VariantTabItemProps {
  onClick: () => void;
  variantName?: string;
  isActive: boolean;
}
const VariantTabItem = React.memo<VariantTabItemProps>(
  ({ variantName, isActive, onClick }) => (
    <div
      className={cn(styles.variantItem, isActive && styles.active)}
      onClick={onClick}
    >
      <p>{variantName}</p>
    </div>
  )
);
