import { atom, selector } from 'recoil';
import { ColourOption, InteriorOption, Variant } from '~/common/models';

export const ActiveVariant = atom<Variant | undefined>({
  key: 'ActiveVariantKey',
  default: undefined,
});

export const ActiveVariantColourOption = selector<ColourOption[]>({
  key: 'ActiveVariantColourOption',
  get: ({ get }) => get(ActiveVariant)?.colourOptions ?? [],
});

export const ActiveVariantInterionOption = selector<InteriorOption[]>({
  key: 'ActiveVariantInterionOption',
  get: ({ get }) => get(ActiveVariant)?.interiorOptions ?? [],
});
