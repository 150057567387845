import React, { useState } from 'react';
import { useRecoilValue } from 'recoil';
import { usePopper } from 'react-popper';
import { Button, FloatingDisclaimer } from '@ui-elements';
import { ColourSwatch } from '~/common/components';
import { CallToAction, ColourOption } from '~/common/models';
import { ActiveVariantColourOption } from '../../store';
import styles from './index.module.scss';

interface ColourOptionsProps {
  heading: string;
  headingTooltip?: string;
  disclaimer?: string;
  cta?: CallToAction;
}

export const ColourOptions = React.memo<ColourOptionsProps>(
  ({ heading, headingTooltip, cta, disclaimer }) => {
    const [activeColour, setActiveColour] = useState<ColourOption>();
    const activeColourOptions = useRecoilValue(ActiveVariantColourOption);

    React.useEffect(() => {
      if (activeColourOptions?.length > 0)
        setActiveColour(activeColourOptions[0]);
    }, [activeColourOptions]);

    return (
      <div className={styles.colourOptionsContainer}>
        <div className={styles.imageWrapper}>
          <img src={activeColour?.frontImageUrl} alt={activeColour?.name} />
        </div>
        <div>
          <div className={styles.contentWrapper}>
            <FloatingDisclaimer
              className={styles.heading}
              disclaimer={headingTooltip}
            >
              {({ renderTrigger }) => (
                <>
                  <div dangerouslySetInnerHTML={{ __html: heading }} />
                  {renderTrigger()}
                </>
              )}
            </FloatingDisclaimer>

            <div className={styles.colourSwatches}>
              <div className={styles.colourLabel}>
                Colour
                {!!activeColour && <span>{activeColour.name}</span>}
              </div>
              <div className={styles.colourList}>
                {activeColourOptions.map((colour, index) => (
                  <ColourSwatch
                    colour={colour}
                    key={colour.colourOptionId}
                    onClick={() => setActiveColour(colour)}
                    isActive={
                      activeColour?.colourOptionId === colour.colourOptionId
                    }
                    delay={(index + 1) * 80}
                  />
                ))}
              </div>

              <div className={styles.ctaWrapper}>
                {!!cta && !cta.ctaHidden && (
                  <Button
                    type={cta.ctaButtonType}
                    target={cta.ctaUrl?.target}
                    href={cta.ctaUrl?.url}
                    buttonSize={cta.ctaButtonSize}
                    buttonWidth={cta.ctaFullWidth}
                  >
                    {cta.ctaUrl?.name}
                  </Button>
                )}
              </div>

              <div
                className={styles.disclaimer}
                dangerouslySetInnerHTML={{ __html: disclaimer as string }}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
);
