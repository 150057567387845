import React, { useState } from 'react';
import cn from 'classnames';
import Carousel from 'react-multi-carousel';
import { useRecoilValue } from 'recoil';
import { CallToAction, InteriorOption } from '~/common/models';
import { ActiveVariantInterionOption } from '../../store';
import { FloatingDisclaimer } from '~/common/components/ui-elements';
import styles from './index.module.scss';

interface InteriorOptionsProps {
  heading: string;
  headingTooltip?: string;
  disclaimer?: string;
  cta?: CallToAction;
}

export const InteriorOptions = React.memo<InteriorOptionsProps>(
  ({ heading, headingTooltip, disclaimer }) => {
    const [activeInterior, setActiveInterior] = useState<InteriorOption>();
    const activeInteriorOptions = useRecoilValue(ActiveVariantInterionOption);

    React.useEffect(() => {
      if (activeInteriorOptions?.length > 0)
        setActiveInterior(activeInteriorOptions[0]);
    }, [activeInteriorOptions]);

    return (
      <div className={styles.interiorOptionsContainer}>
        <div>
          <div className={styles.contentWrapper}>
            <FloatingDisclaimer
              className={styles.heading}
              disclaimer={headingTooltip}
            >
              {({ renderTrigger }) => (
                <>
                  <div dangerouslySetInnerHTML={{ __html: heading }} />
                  {renderTrigger()}
                </>
              )}
            </FloatingDisclaimer>

            <div className={styles.interiorLabel}>
              Interior
              {!!activeInterior && <span>{activeInterior.description}</span>}
            </div>

            {!!activeInterior && (
              <InteriorImageAssets
                isMobile
                imageAssets={activeInterior.imageAssets}
              />
            )}

            <div className={styles.interiorList}>
              {activeInteriorOptions.map((interior) => (
                <InteriorItem
                  key={interior.interiorOptionId}
                  imageUrl={interior.imageUrl}
                  description={interior.description}
                  onClick={() => setActiveInterior(interior)}
                  isActive={
                    interior.interiorOptionId ===
                    activeInterior?.interiorOptionId
                  }
                />
              ))}
            </div>

            <div
              className={styles.disclaimer}
              dangerouslySetInnerHTML={{ __html: disclaimer as string }}
            />
          </div>
        </div>
        <div className={styles.imageWrapper}>
          {!!activeInterior && (
            <InteriorImageAssets imageAssets={activeInterior.imageAssets} />
          )}
        </div>
      </div>
    );
  }
);

interface InteriorItemProps {
  imageUrl: string;
  description: string;
  onClick: () => void;
  isActive: boolean;
}

const InteriorItem = React.memo<InteriorItemProps>(
  ({ imageUrl, description, onClick, isActive }) => (
    <div
      className={cn(styles.interiorItem, isActive && styles.active)}
      onClick={onClick}
    >
      <img src={imageUrl} alt={description} />
      <p>{description}</p>
    </div>
  )
);

interface InteriorImageAssetsProps {
  imageAssets: string[];
  isMobile?: boolean;
}

const InteriorImageAssets = React.memo<InteriorImageAssetsProps>(
  ({ imageAssets, isMobile }) => {
    return (
      <Carousel
        arrows
        showDots
        swipeable
        ssr={false}
        renderDotsOutside
        containerClass={cn(
          styles.carouselContainerClass,
          isMobile && styles.mobileOnly
        )}
        responsive={{
          desktop: {
            breakpoint: { max: 4000, min: 1024 },
            items: 1,
            slidesToSlide: 1,
          },
          tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 1,
            slidesToSlide: 1,
          },
          mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1,
            slidesToSlide: 1,
          },
        }}
      >
        {imageAssets.map((img, index) => (
          <img key={index} src={img} alt={img} />
        ))}
      </Carousel>
    );
  }
);
