import React from 'react';
import { MutableSnapshot, RecoilRoot } from 'recoil';
import { CallToAction, Variant } from '~/common/models';
import { VariantTab } from './components/variant-tab';
import { ColourOptions } from './components/colour-option';
import { InteriorOptions } from './components/interior-option';
import { ActiveVariant } from './store';
import styles from './index.module.scss';

interface ModelColourInteriorProps {
  contentId: string;
  variants: Variant[];
  colourHeading: string;
  colourHeadingTooltip?: string;
  colourCta?: CallToAction;
  colourDisclaimer?: string;
  interiorHeading: string;
  interiorHeadingTooltip?: string;
  interiorDisclaimer?: string;
}

export const ModelColourInterior = React.memo<ModelColourInteriorProps>(
  (props) => {
    const initialize = ({ set }: MutableSnapshot) => {
      set(ActiveVariant, props.variants[0]);
    };

    return (
      <RecoilRoot initializeState={initialize}>
        <div id={`cid-${props.contentId}`} className={styles.colourInterior}>
          <VariantTab variants={props.variants} />

          <ColourOptions
            heading={props.colourHeading}
            headingTooltip={props.colourHeadingTooltip}
            disclaimer={props.colourDisclaimer}
            cta={props.colourCta}
          />

          <InteriorOptions
            heading={props.interiorHeading}
            headingTooltip={props.interiorHeadingTooltip}
            disclaimer={props.interiorDisclaimer}
          />
        </div>
      </RecoilRoot>
    );
  }
);
